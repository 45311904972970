import { ContentfulUiComponent, ContentfulUiComponentItem } from '@truckmap/common';
import { serializeComponent } from 'lib/contentful/contentfulSerializers';
import { mapEntriesByType } from 'lib/contentful/mapEntriesByType';
import { createContext, useContext, useMemo } from 'react';
import { ContentfulQueryProps } from 'types/common';
import { SerializedComponent } from 'types/contentful';
import {
  IPage,
  IUIComponent,
  IUIComponentFields,
  IUIComponentItemFields
} from 'types/generated/contentful';
import { GeneratedSectionLayouts } from 'types/translationKeys';

export const useContentfulEntry = (_entry: IUIComponent) =>
  useMemo(() => serializeComponent(_entry), [_entry]);

export const useContentfulEntriesByType = (serializedEntries: SerializedComponent) =>
  useMemo(() => mapEntriesByType(serializedEntries.items), [serializedEntries.items]);

/**
 * Takes a UIComponent from contentful and serializes the component and items.
 * @param input UIComponent
 * @returns Entries serialized and organized by type.
 */
export function useContentfulSerializer(_entry: IUIComponent) {
  const entry = useContentfulEntry(_entry);
  const items = useContentfulEntriesByType(entry);

  return { entry, items };
}

export const ContentfulContext = createContext<ContentfulQueryProps>(null);

export const useContentfulQuery = () => useContext(ContentfulContext)?.contentfulQuery;

export const useContentfulUiComponentFromPage = (
  page: IPage,
  sectionLayoutName: GeneratedSectionLayouts
) => {
  return useMemo(
    () =>
      page.fields.uiComponents.find(
        ({ fields: { sectionLayout } }) => sectionLayout === sectionLayoutName
      ),
    [page]
  );
};

export const useContentfulUiComponentFromQuery = (sectionLayoutName: GeneratedSectionLayouts) => {
  const { uiComponentCollection = null } = useContentfulQuery();

  return useMemo(
    () =>
      uiComponentCollection?.items?.find(
        ({ sectionLayout }) => sectionLayout === sectionLayoutName
      ),
    [uiComponentCollection]
  );
};

export const useContentfulUiComponentItems = (
  component: ContentfulUiComponent | ContentfulUiComponentItem,
  componentItemType: IUIComponentFields['type'] | IUIComponentItemFields['type']
) => {
  return useMemo(
    () => component.itemsCollection.items.filter(({ type }) => type === componentItemType),
    [component, componentItemType]
  );
};
